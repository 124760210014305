'use strict'

const _ = require('lodash')
const dataWhitelist = [
    'component_properties',
    'document_data',
    'design_data',
    'behaviors_data',
    'connections_data',
    'theme_data',
    'mobile_hints',
    'anchors_data',
    'layout_data',
    'breakpoints_data',
    'variants_data',
    'transformations_data',
    'transitions_data'
]

module.exports = pageJson => {
    pageJson.data = _.pick(pageJson.data, dataWhitelist)
}
